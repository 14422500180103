var divLoaderImg = '<div class="div-loader"><img src="/images/ajax-loader.gif" title="Loading..." /></div>';
var unexpectedError = "There is an error processing your request, refresh the page and try again, if the problem persist contact with the Web Administrator";

/*document.addEventListener('DOMContentLoaded', function() {
    
    //alert("Ready!");

}, false);*/

 // When the user scrolls the page, execute myFunction
window.onscroll = function() {
  if($(navbar).length > 0){
    myFunction();
  }

};

// Get the navbar
var navbar = document.getElementById("header-bottom");

// Get the offset position of the navbar
if($(navbar).length > 0){
  var sticky = navbar.offsetTop;
}

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  //console.log(sticky);
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")

    //$("#nav-container #secondary-sticky-links").show();

    //$("#nav-container nav.main-navbar ul").addClass("with-secondary");
  } else {
    navbar.classList.remove("sticky");
    //$("#nav-container #secondary-sticky-links").hide();
    //$("#nav-container nav.main-navbar ul").removeClass("with-secondary");
  }
} 

function scrollToDiv(ele){
      //alert(ele);
      var navH = $("#header-bottom").height();
      var eleTop = $(ele).offset().top;

      //alert($(ele).offset().top - parseInt(navH));
      //alert($("#nav-container").hasClass('sticky'));
      if($(".header-bottom").hasClass('sticky')){
        //console.log("sticky opti"+navH);
        var eleOffset = eleTop - parseInt(navH);
      }else{
        var eleOffset = eleTop - (parseInt(navH)*2-5);
      }

      //console.log(eleTop);
      //console.log(navH);
      $('html, body').animate({
        scrollTop: eleOffset
      }, 1000);

}

$(document).ready(function() {

    var headerH = $('header').height();
    var minusH = headerH + 100;
    var bodyH = $(window).height();
    //console.log("body-h:"+bodyH);
    var contentInn = bodyH - minusH;
    $("#home-page").find(".content-inner").height(contentInn);

    /*window.onbeforeunload = function (e) {
        e = e || window.event;

        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = 'Sure?';
        }

        // For Safari
        return 'Sure?';
    };*/

    //alert(window.location.hash);
    //var ele = $('#' + window.location.hash.replace('#', ''));
    var urlHash = window.location.hash;
    if(urlHash !=  ''){
      //var ele = urlHash.replace("#", "");
      //alert(ele);
      scrollToDiv(urlHash);
    }

    $('.navbar-nav>li>a').on('click', function(){
        $('.navbar-collapse').collapse('hide');
    });

    $(".warning-link").on('click',function(e){
        e.preventDefault();
        var href = $(this).attr('href');
        //alert(href);
        var msg = '<div class="text-center" style="color:#8A1313">Please confirm you wish to visit the<br>'
                +"2Birds Investor website<br>"
                +"(the page will open in a new tab)</div>";
        Swal.fire({
                title: 'Warning!...',
                html: msg, 
                //icon: 'warning',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                cancelButtonText: "No"
            }).then((result) => {
                if (result.value) {
                    window.open(href, '_blank');
                    //alert("aa");
                }else{
                    //alert("addd");
                }
            });
    });

    //
    /*if(elem) {
        $.scrollTo(elem.left, elem.top);
    }*/

   /*$('.projects-slider').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        nextArrow: '<div class="button-overlay prevArrowBtn"></div>',
        prevArrow: '<div class="button-overlay nextArrowBtn"></div>',
        responsive: [
        {
          breakpoint: 1924,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            //infinite: false,
            //dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });*/

    /*$('.projects-det-slider1').slick({
        autoplay:true,
        dots: false,
        infinite: true,
        speed: 700,
        autoplaySpeed:1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: false,
        nextArrow: '<div class="icon silk-custom-arrow fas fa-arrow-circle-right nextArrowBtn"></div>',
        prevArrow: '<div class="icon silk-custom-arrow fas fa-arrow-circle-left prevArrowBtn"></div>',
        responsive: [
        {
          breakpoint: 1924,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            //infinite: false,
            //dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });*/

   $(".top-nav a, a.scroll-to-cont").click(function (e){

      //myFunction();
      //alert("aaa");

      var ele = $(this).attr("data-id");
      if (ele === undefined)
        return true;
      else
        e.preventDefault();

      $(".top-nav a").each(function(){
        $(this).removeClass("active");
      });

      $(this).addClass("active");

      console.log(ele);

      /*var toggleBtn = $(".navbar-expand-lg .navbar-toggler").css('display');

      if(toggleBtn == "block"){
        $("#main-navbar .navbar-toggler").trigger("click");
        var navH = $(".navbar-toggler").height();
      }else{
        var navH = $("#nav-container").height();
      }*/

      scrollToDiv(ele);
      

  });

  
  // Gets the video src from the data-src on each button

  var $videoSrc;  
  $('.video-btn').click(function() {
      $videoSrc = $(this).data( "src" );
  });
  //console.log($videoSrc);
    
  // when the modal is opened autoplay it  
  $('#myModal').on('shown.bs.modal', function (e) {
    // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
    $("#video").attr('src',$videoSrc + "?allowfullscreen=1&autoplay=1&amp;modestbranding=1&amp;showinfo=0" ); 
  })
    
  // stop playing the youtube video when I close the modal
  $('#myModal').on('hide.bs.modal', function (e) {
      // a poor man's stop video
      $("#video").attr('src',$videoSrc); 
  }) 

  /* pdf open link */
  var $pdfSrc;  
  $('.pdf-link').click(function(e) {
      e.preventDefault();
      //alert("aaa");
      $pdfSrc = $(this).prop( "href" );
      $('#pdfModal').modal('show');
  });
    
  // when the modal is opened autoplay it  
  $('#pdfModal').on('shown.bs.modal', function (e) {
    $pdfSrc
    // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
    $("#pdf-embed").attr('src',$pdfSrc ); 
  });
    
  // stop playing the youtube video when I close the modal
  $('#pdfModal').on('hide.bs.modal', function (e) {
      // a poor man's stop video
      $("#pdf-embed").attr('src',$videoSrc); 
  });

   var pageLink;  
  $('.page-link').click(function(e) {
      e.preventDefault();
      //alert("aaa");
      pageLink = $(this).prop( "href" );
      $('#pdfModal').modal('show');
  });
    
  // when the modal is opened autoplay it  
  $('#pageModal').on('shown.bs.modal', function (e) {
    //pageLink
    // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
    $("#pageModal .modal-body-inner").html(pageLink ); 
  })
    
  // stop playing the youtube video when I close the modal
  $('#pageModal').on('hide.bs.modal', function (e) {
      // a poor man's stop video
      
  });


window.loadFormPlugins = function(ele){


        if($(ele).find(".datetimepicker").length){
            $(ele).find('.datetimepicker').datetimepicker({
                format: 'DD-MM-YYYY h:mm:ss A',
                ignoreReadonly: true,
                allowInputToggle: true,
                useCurrent: false
                //dayViewHeaderFormat: 'MMMM YYYY',
            });
        }

        if($(ele).find(".datepicker").length){

            $(ele).find(".datepicker").each(function(){

                window.dateTimePickerOpertion(this);

            });
        }

        if($(ele).find(".ni-mask").length){
            $(ele).find(".ni-mask").inputmask("aa-99-99-99-a");
        }

        if($(ele).find(".sortcode-mask").length){
            $(ele).find(".sortcode-mask").inputmask("99-99-99");
        }

        if($(ele).find(".cnic-mask").length){
            $(ele).find(".cnic-mask").inputmask("99999-9999999-9");
        }

        if($(ele).find(".mobile-no-mask").length){
            $(ele).find(".mobile-no-mask").inputmask("03999999999");
        }

        //console.log($(ele).attr("id"));
        //console.log("select2 test");
        if($(ele).find(".select2").length){
            //console.log("select2 test");
            $(ele).find('.select2').select2({
                placeholder: {
                    id: '-1', // the value of the option
                    text: 'Select an option'
                  }
                //,"readonly": readonly
             /* theme: 'bootstrap4',
              width: 'style',
              placeholder: $(this).attr('placeholder'),
              allowClear: Boolean($(this).data('allow-clear')),*/
            });
        } 
    }

    window.dateTimePickerOpertion = function(ele){
        $(ele).datetimepicker({
            //debug: true,
            format: 'DD-MM-YYYY',
            ignoreReadonly: true,
            allowInputToggle: true,
            useCurrent: false
            /*minDate: minDateStr,
            maxDate: maxDateStr,
            defaultDate: defaultDateStr,*/
        });
        $(ele).on("dp.show", function (e,maxDateStr) {
            var dataDate = $(ele).attr("data-date");
            if(typeof dataDate != 'undefined'){
                
                var dateStr = new Date(dataDate);
                var defaultDateStr = dateStr; 
            }else{
                var defaultDateStr = false;
            }

             var minDate = $(ele).attr("data-minDate");
            if(typeof minDate != 'undefined' && minDate != ""){
                var dateStr = new Date(minDate);
                var minDateStr = dateStr; 
            }else{
                var minDateStr = false;
            }

            var maxDate = $(ele).attr("data-maxDate");
            if(typeof maxDate != 'undefined' && maxDate != ""){
                var dateStr = new Date(maxDate);
                var maxDateStr = dateStr; 
            }else{
                var maxDateStr = false;
            }
                //console.log(maxDateStr);
            $(ele).data("DateTimePicker").minDate(minDateStr);
            $(ele).data("DateTimePicker").maxDate(maxDateStr);
            //$(ele).data("DateTimePicker").startDate(false);
        });
    }


window.displayDivLoader = function(ele){
    $(ele).html(divLoaderImg);
}

window.hideDivLoader = function(ele){
    $(ele).html("");
}

window.LoadForm = function (url, ele, msg = "") {
    window.displayDivLoader(ele);
    
    $.ajax({
        url: url,
        method: 'GET',
        data: {msg:msg, msgtype:'success'},
        cache:false,
        processData: false,
        contentType: false,
        dataType: "json",
        success: function(data){

            if(data.success){
                $(ele).html(data.html);  
                window.loadFormPlugins(ele);
            }else if(data.errors){

                var errorHtml = '<ul class="alert-danger sweet-alert-error">';
                $.each(data.errors, function(key, value){
                   errorHtml += "<li>"+value+"</li>";
                });
                errorHtml += "</ul>";

                window.hideDivLoader(ele);

                swal.fire({  
                    title: "Error! ...",   
                    html: errorHtml ,   
                    type: "error",   
                    confirmButtonText: "Close",
                    focusConfirm:true 
                    }
                    ,function(isConfirm) {
                    
                    }
                );
            }else{
                window.hideDivLoader(ele);
                 swal.fire({  
                    title: "Error! ...",   
                    html: unexpectedError ,   
                    type: "error",   
                    confirmButtonText: "Close",
                    focusConfirm:true 
                    }
                    ,function(isConfirm) {
                    
                    }
                );
             }
            
        },
        error: function(e) {
            console.log(e);
        }
    });
}

  $(document).on("submit", "form.ajax-submit" , function(e) {
         e.preventDefault();

         $("label.error").remove();
         $('.error').removeClass('error');
         $('.response').hide().html("");

         var form = $(this).attr('id');

         Swal.fire({
            title: "Please Wait! ...",   
            text: "", 
            imageUrl: "/images/ajax-loader.gif",
            showCloseButton: false, 
            showCancelButton: false, 
            showConfirmButton: false
        });

       //var formData = form.serialize();
       var formData =  new FormData(this);

       $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

       var formAction = $(this).attr('action');
        //alert(formAction);

        $.ajax({
            url: formAction,
            method: 'POST',
            data: formData,
            cache:false,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function(data){
                if(data.errors){

                    //console.log(data);

                    /*$.each(data.errors, function(key, value){

                        $('.alert-danger').show();
                        $('.alert-danger').append('<p>'+value+'</p>');

                    });*/

                    var errorHtml = '<ul class="alert-danger sweet-alert-error">';
                    $.each(data.errors, function(key, value){
                        errorHtml += "<li>"+value+"</li>";
                    });

                    errorHtml += "</ul>";

                    $('.response').show().append(errorHtml);

                    $(this).find('input, textarea, select').each(function(){

                        var error = data.errors[$(this).attr("name")] ? data.errors[$(this).attr("name")] : null;

                    
                        if(error) {
                            if($(this).parent().hasClass("date")){
                                $(this).parent().addClass("error").after('<label class="error">' + error + '</label>');
                            }else{
                                $(this).addClass("error").after('<label class="error">' + error + '</label>');
                            }
                        }
                    });

                    //var errorText = $('.alert-danger').get();
                    //alert(errorHtml);

                    
                    Swal.fire({  
                        title: "Error! ...",   
                        html: errorHtml ,   
                        type: "error",   
                        confirmButtonText: "Close" 
                    }
                        ,function(isConfirm) {

                    }
                    );
                }else{
                    /*alert(data.msg);
                    return false;
                    Swal.close();*/

                    
                    $(".form-control[type='text'], .form-control[type='email']").val('');
                    $(".form-control").prop('checked', false);

                    $(".disable-overlay").hide();
                    $(".register-d-btn").prop('disabled', false);
                    $(".register-d-btn").removeClass('disabled');

                    $(".reg-display").hide();
                    $(".login-display").show();

                    if(data.container && data.container != ''){
                        window.LoadForm(data.url
                            , data.container, data.msg);

                        swal.close();
                        return false;
                    }

                    /*var type = "";
                    var title = "";
                    if(data.alertType != undefined && data.alertType == true){
                        var type = "success";
                        var title = "Success! ...";
                    }*/

                    Swal.fire({  
                        title: "Success! ...",   
                        html: data.msg ,   
                        type: "success",   
                        confirmButtonText: "Close",
                        focusConfirm:true 
                        }
                        ,function(isConfirm) {
                        
                        }
                    );

                    //swal.close();
                }
            },
            error: function(e) {
                console.log(e);
            }
        });

        return false;
    });

    $(document).on("click", 'input[name="tax_relief"]' , function(e) {
        var thisVal = $(this).attr("value");

        if($(this).attr("value") == 1){
            $("#ni-wrapper").show();
        }else{
            $("#ni-wrapper").hide();
            $("#ni").val('');
        }
    });

    $(document).on("blur", '.address-ele' , function(e) {
        $transferBankShow = true;
        $(".address-ele").each(function(){
            if($(this).val() == ""){
                $transferBankShow = false;
                return false;
            }
        });
        console.log($transferBankShow);
        if($transferBankShow){
            $("#transfer_bank_wrap").css('display', 'flex');
            $("#transfer_bank_show").val("1");
        }else{
            $("#transfer_bank_show").val("0");
            $("#transfer_bank_wrap").hide();
        }
    });

    $(document).on("change", 'select.address-ele' , function(e) {
        $transferBankShow = true;
        $(".address-ele").each(function(){
            if($(this).val() == ""){
                $transferBankShow = false;
                return false;
            }
        });
        console.log($transferBankShow);
        if($transferBankShow){
            $("#transfer_bank_show").val("1");
            $("#transfer_bank_wrap").css('display', 'flex');
        }else{
            $("#transfer_bank_show").val("0");
            $("#transfer_bank_wrap").hide();
        }
    });

    $(document).on("change", 'input[name="transfer_funds"]' , function(e) {
        var thisVal = $(this).attr("value");

        window.resetEleInside("#bank-account-wrap");
        window.resetEleInside("#invest-entity-wrap");
        window.resetEleInside("#details-another");
        window.resetEleInside("#details-company");
        window.resetEleInside("#address-entity");
        window.resetEleInside("#other-baccount-wrap");

        $("#transfer_bank_wrap").hide();
        $("#transfer_bank_show").val("0");

        if($(this).attr("value") == 1){
            $("#bank-account-wrap").show();
            $("#invest-entity-wrap").hide();

            $("#details-another").hide();
            $("#details-company").hide();

            $("#address-entity").hide();
            $("#other-baccount-wrap").hide();
            
        }else{
            $("#bank-account-wrap").hide();
            $("#invest-entity-wrap").css('display', 'flex');

            

            //console.log($('input[name="invest_entity"]:checked').val());
            /*if($('input[name="invest_entity"]:checked').val() == 1){
                $("#details-another").show();
                $("#details-company").hide();
            
            }else if($('input[name="invest_entity"]:checked').val() == 2){
                $("#details-another").hide();
                $("#details-company").show();
            }

            if($('input[name="invest_entity"]:checked').val() == 1 || $('input[name="invest_entity"]:checked').val() == 2){
                $("#address-entity").show();

                if($('input[name="transfer_bank"]:checked').val() == 1){
                    $("#other-baccount-wrap").show();

                    $("#bank-det-uk").show();
                    $("#bank-det-foreign").hide();
                
                }else if($('input[name="transfer_bank"]:checked').val() == 2){
                    $("#other-baccount-wrap").show();

                    $("#bank-det-uk").hide();
                    $("#bank-det-foreign").show();
                }
            }*/
        }
    });

    window.resetEleInside = function(ele){

       $(ele).find("select").each(function () { 
            $(this).find("option:selected").prop('selected', false);
            $(this).select2("");
        });

        $(ele).find('input:text, input:password')
            .each(function () {
                $(this).val('');
            });

        $(ele).find('input[type="radio"]')
            .each(function () {
                $(this).prop('checked', false);
            });



        //$(".select2 :selected").prop('selected', false);
        
    }

    $(document).on("change", 'input[name="invest_entity"]' , function(e) {
        var thisVal = $(this).attr("value");

        window.resetEleInside("#details-another");
        window.resetEleInside("#details-company");
        window.resetEleInside("#address-entity");
        window.resetEleInside("#other-baccount-wrap");

        $("#transfer_bank_show").val("0");

        if($(this).attr("value") == 1){
            $("#details-another").show();
            $("#details-company").hide();
            
        }else{
            $("#details-another").hide();
            $("#details-company").show();
        }

        $("#address-entity").show();
        $("#other-baccount-wrap").hide();
        $("#transfer_bank_wrap").hide();
        

        //$("#other-baccount-wrap").show();

    });


    $(document).on("change", 'input[name="transfer_bank"]' , function(e) {
        var thisVal = $(this).attr("value");

        window.resetEleInside("#other-baccount-wrap");
       /* window.resetEleInside("#bank-det-uk");
        window.resetEleInside("#bank-det-foreign");*/

        $("#other-baccount-wrap").show();
        if($(this).attr("value") == 1){
            $("#bank-det-uk").show();
            $("#bank-det-foreign").hide();
            
        }else{
            $("#bank-det-uk").hide();
            $("#bank-det-foreign").show();
        }

    });

    $(document).on("click", ".url-ajax-form" , function(e) {
        e.preventDefault();
        var dataUrl = $(this).attr("data-href");
        var dataContainer = $(this).attr("data-container");

        window.LoadForm(dataUrl, dataContainer, '');
    });

    $(document).on("click", ".btn-to-radio" , function(e) {
        var thisVal = $(this).attr("data-val");
        var thisTarget = $(this).attr("data-target");

        $(".btn-to-radio").each(function(){
            $(this).removeClass("selected");
        });
        $(this).addClass("selected");

        $(".radio-card").each(function(){
            $(this).removeClass("selected");
        });
        $(".invest-cat-"+thisVal).addClass("selected");

        $(thisTarget).val(thisVal);
    });

/* ****************** for url ajax get action ************** */
    $(document).on("click", ".url-ajax-action" , function(e) {
        e.preventDefault();
        var dataUrl = $(this).attr("data-href");
          //alert("aaa");

        //$("#result-show").hide();
        $("label.error").remove();
        $('.error').removeClass('error');
        $('.response').hide().html("");

        var form = $(this).attr('id');

        Swal.fire({
            title: "Please Wait! ...",   
            text: "", 
            imageUrl: "/images/ajax-loader.gif",
            showCloseButton: false, 
            showCancelButton: false, 
            showConfirmButton: false
        });

        $.ajaxSetup({
          headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          }
        });
        
        //alert(formAction);

        $.ajax({
            url: dataUrl,
            method: 'GET',
            data:"",
            cache:false,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function(data){
                if(data.success){

                    swal.fire({  
                        title: "Success! ...",   
                        html: data.msg ,   
                        type: "success",   
                        confirmButtonText: "Close",
                        focusConfirm:true 
                        }
                    );
                    
                }else{

                    if(typeof data.msg != undefined){
                        var msg = data.msg; 
                    }else{
                        var msg = "There is an error processing your request, refresh the page and try again, if the problem persist contact with the Web Master";
                    }

                    swal.fire({  
                        title: "Error! ...",   
                        html: msg,   
                        type: "error",   
                        confirmButtonText: "Close",
                        focusConfirm:true 
                        }
                    );

                }
            },
            error: function(e) {
                console.log(e);
            }
        });
        
    });


    $(document).on("click", ".open-modal" , function() {
        var ele = this;
        var url = $(ele).attr('data-href');
        //var receiptModel = $('#receipt-model');
        var dataTarget = $(ele).attr('data-target');
        //alert(dataTarget);
        var modalEle = $(dataTarget);
        var dataModeltype = $(ele).attr('data-data-modeltype');

        //window.displayDivLoader(ele);

        //var deposit_date = $("deposit_date").val();

        Swal.fire({
            title: "Please Wait! ...",   
            text: "", 
            imageUrl: "/images/ajax-loader.gif",
            showCloseButton: false, 
            showCancelButton: false, 
            showConfirmButton: false
        });

        $.ajax({
            url: url,
            method: 'GET',
            data: "",
            cache:false,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function(data){

                if(data.success){
                    //$(ele).html(data.html);
                   
                    //window.loadActBtnPlugins();
                    modalEle.find(".modal-dialog").removeClass("modal-lg")
                                            .removeClass("modal-m")
                                            .addClass(dataModeltype);

                    if(data.title != ''){
                        modalEle.find(".modal-header").show();
                        modalEle.find(".modal-title").html(data.title);
                    }else{
                        modalEle.find(".modal-header").hide();
                    }

                    if(data.footer != undefined && data.footer == false){
                        modalEle.find(".modal-footer").hide();
                    }else{
                        modalEle.find(".modal-footer").show();
                    }
                    
                    modalEle.find(".modal-body").html(data.html);
                    modalEle.modal({show:true,backdrop: 'static',keyboard: false});

                    swal.close();
                    window.loadFormPlugins(modalEle);
                }else{
                     swal.fire({  
                        title: "Error! ...",   
                        html: unexpectedError ,   
                        type: "error",   
                        confirmButtonText: "Close",
                        focusConfirm:true 
                        }
                        ,function(isConfirm) {
                        
                        }
                    );
                }
            },
            error: function(e) {
                console.log(e);
            }
        });
    });

    /*$(document).on("blur", ".invest-add" , function(e) {
        var totalInvest = 0
        $(".invest-add").each(function(){
            var thisVal = $(this).val();
            totalInvest = +totalInvest + +thisVal;
        });

        $('#total-invest-t').val(totalInvest);
        $('#total-invest').val(totalInvest);
    });*/

    $(document).on({
        keyup: function() {
          window.formatCurrency($(this));
        },
        blur: function() { 
          window.formatCurrency($(this), "blur");
        }
    },"input[data-type='currency']");


    window.formatNumber = function(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    window.formatCurrency = function(input, blur) {
          // appends $ to value, validates decimal side
          // and puts cursor back in right position.
          
          // get input value
          var input_val = input.val();
          
          // don't validate empty input
          if (input_val === "") { return; }
          
          // original length
          var original_len = input_val.length;

          // initial caret position 
          var caret_pos = input.prop("selectionStart");
            
          // check for decimal
          if (input_val.indexOf(".") >= 0) {

            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimal_pos = input_val.indexOf(".");

            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);

            // add commas to left side of number
            left_side = window.formatNumber(left_side);

            // validate right side
            right_side = window.formatNumber(right_side);
            
            // On blur make sure 2 numbers after decimal
            if (blur === "blur") {
              right_side += "00";
            }
            
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            //input_val = "$" + left_side + "." + right_side;
            input_val = left_side + "." + right_side;

          } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = window.formatNumber(input_val);
            //input_val = "$" + input_val;
            input_val = input_val;
            
            // final formatting
            if (blur === "blur") {
              input_val += ".00";
            }
          }
          
          // send updated string to input
          input.val(input_val);

          // put caret back in the right position
          var updated_len = input_val.length;
          caret_pos = updated_len - original_len + caret_pos;
          input[0].setSelectionRange(caret_pos, caret_pos);
    }

    $(document).on("blur", "#min-invest" , function(e) {
        var thisVal = $(this).val();
        var invShare = $("#invest-perc").val();
        var thisV = thisVal.replaceAll(",", "");
        //var regex = /[+-]?\d+(\.\d+)?/g;
        //var thisV = thisVal.match(regex);
        //console.log(thisV);
        var totalShare = ((parseFloat(thisV) / parseFloat(invShare) ));

        //console.log(totalShare);
        totalShare = Math.ceil(totalShare);
        //console.log(totalShare.toFixed(2));

        $('#invest-share').val(totalShare);
        $('#total-invest').val(thisVal);
        $('#invest-share-t').html(totalShare.toLocaleString());
    });

    $(document).on("hover", ".dropdown-submenu > a" , function(e) {
  //$('.dropdown-submenu > a').on("hover", function(e) {
      var submenu = $(this);
      $('.dropdown-submenu .dropdown-menu').removeClass('show');
      submenu.next('.dropdown-menu').addClass('show');
      e.stopPropagation();
  });

  $(document).on("hidden.bs.dropdown", ".dropdown" , function(e) {
  //$('.dropdown').on("hidden.bs.dropdown", function() {
      // hide any open menus when parent closes
      $('.dropdown-menu.show').removeClass('show');
  });

});