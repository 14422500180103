/*if (document.images) {
	img1 = new Image();
	//img2 = new Image();
	//img3 = new Image();

	//img1.src = "../images/ajax-loader.gif";
	img1.src = "../images/preloader-1.gif";
}

$(document).ready(function($) {
    var Body = $('body');
    console.log("class added");
    //Body.addClass('preloader-site');
});

$(window).on("load", function() {
    $('.preloader-wrapper').fadeOut();

    
    var Body = $('body');
    if(Body.length > 0){
    	console.log("body found");
    }
    Body.removeClass('preloader-site');
  });*/


/*;(function(){
  function id(v){ return document.getElementById(v); }
  function loadbar() {
    var ovrl = id("overlay"),
        prog = id("progress"),
        stat = id("progstat"),
        img = document.images,
        c = 0,
        tot = img.length;
    if(tot == 0) return doneLoading();

    function imgLoaded(){
      c += 1;
      var perc = ((100/tot*c) << 0) +"%";
      prog.style.width = perc;
      stat.innerHTML = "Loading "+ perc;
      if(c===tot) return doneLoading();
    }
    function doneLoading(){
      ovrl.style.opacity = 0;
      setTimeout(function(){ 
        ovrl.style.display = "none";
      }, 1200);
    }
    for(var i=0; i<tot; i++) {
      var tImg     = new Image();
      tImg.onload  = imgLoaded;
      tImg.onerror = imgLoaded;
      tImg.src     = img[i].src;
    }    
  }
  document.addEventListener('DOMContentLoaded', loadbar, false);
}());*/

//$(document).ready(function($) {

	img1 = new Image();
	img1.src = "../images/logo.png";

	var Body = $('body');
	//Body.addClass('preloader-site');

  perfData = window.performance.timing, // The PerformanceTiming interface
  EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart), // Calculated Estimated Time of Page Load which returns negative value.
  totalTime = parseInt((EstimatedTime/1000)%60)*100;

  //console.log("performancetiming:"+totalTime);
  var t0 = window.performance.now();

  function id(v){ return document.getElementById(v); }

  function loadbar() {
    var ovrl = id("overlay");
      /*  prog = id("progress"),
      stat = id("progstat"),*/
    img = document.images,
    c = 0,
    tot = img.length;
    /*if(tot == 0) 
    return doneLoading();*/

    function imgLoaded(){
    	c += 1;
    	var percVal = ((100/tot*c) << 0);
    	var percTxt= percVal +"%";
    	var cName = "p"+percVal;
      //prog.style.width = perc;
      //stat.innerHTML = "Loading "+ perc;

     /* $(".progress").removeClass (function (index, className) {

      		if(cName != className){
		    	return (className.match (/(^|\s)p\S+/g) || []).join(' ');
		    }
      });*/

      $(".progress").addClass(cName);
      $(".progress span").html(percTxt);
      if(c===tot) return doneLoading();
    }

    function doneLoading(){
      ovrl.style.opacity = 0;
      setTimeout(function(){ 
       ovrl.style.display = "none";
     }, 1200);
    }

    for(var i=0; i<tot; i++) {
      var tImg     = new Image();
      tImg.onload  = imgLoaded;
      tImg.onerror = imgLoaded;
      tImg.src     = img[i].src;

    }    
  }
//loadbar();
//document.addEventListener('DOMContentLoaded', loadbar, false);

  totalTime = Math.abs(totalTime);
  console.log(totalTime);
  totalTime = totalTime - (totalTime/2);
  console.log(totalTime);

  var cName = "p0";
  function myTimer() {
    //console.log(' each 1 second...');
    var t1 = window.performance.now();

    var curTime = t1 - t0;
    //console.log("currTime:"+curTime);
    //console.log("totalTime:"+totalTime);

    //var curTimeMs = parseInt((curTime/1000)%60)*100;

    //console.log("curTimeMs"+curTimeMs);
    totalTime = Math.abs(totalTime);
    if(totalTime <= 0){
      var percVal = 100;
    }else{
      var percVal = (((curTime/totalTime)*100) << 0);
    }

    if(percVal > 100){
      percVal = 100;
    }

    if(percVal < 0){
      percVal = 0;
    }

    $(".progress").removeClass(cName);

    var percTxt= percVal +"%";
    cName = "p"+percVal;

    $(".progress").addClass(cName);
    $(".progress span").html(percTxt);

    if(percVal >= 100){
      var Body = $('body');
      $('#overlay').fadeOut();
      
      clearInterval(myVar);
    }
    //console.log("perc Val"+percVal);
  }

  var myVar = setInterval(myTimer, 10);

//});

$(window).on("load", function() {

  //clearInterval(myVar);
  // PAGE IS FULLY LOADED  
  // FADE OUT YOUR OVERLAYING DIV

  var Body = $('body');
  //Body.removeClass('preloader-site');
  /*$(".progress").addClass("p100");
  $(".progress span").html("100%");*/

  $('#overlay').fadeOut();
  /*$("#overlay").animate({
    opacity: 0,
  }, 1200, function() {
    // Animation complete.
    $(this).hide();
  });*/
  
});
